import {
  AttachmentFileItem,
  BufferPackageType,
  FileDataItem,
  FulfillmentTruckCompany,
  InspectionStatus,
  MaterialItem,
  MaterialPackageType,
  OutsidePackageType,
  PackageCategory,
  PackageItem,
  PackageTabItem,
  PackageType,
  ProviderType,
  ReceivingAndInspectionProblem,
  ReceivingStatus,
  TapePackageType,
} from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { ReceivingType } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import { FulfillmentParcelCompany } from "@sellernote/_shared/src/types/fulfillment/fulfillment";
import {
  ShippingDeliveryType,
  ShippingPacking,
  ShippingTrucking,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import { ResponseFailureInfo } from "../../types/common/common";
import { CommonTruckType } from "../../types/fulfillment/common";

import { COLOR } from "../../stylesToMoveToV1/constants";

function getReceivingStatusString(
  status: ReceivingStatus | undefined,
  inspectionStatus: InspectionStatus | undefined
) {
  switch (status) {
    // 입고 요청 리스트(Admin)
    case "beforeReceiving":
      return "도착 전";
    case "waitingInspection":
      return "검수 대기 중";

    // 입고 작업 리스트(Admin)
    case "inspecting":
      return "검수 중";
    // 검수 완료 (정상 / 불일치 / 동의)
    case "completeInspection":
      if (inspectionStatus === "hold") return "검수완료(불일치)";
      return "";
    case "putAway":
      /**
       * 정상 검수인 경우 putAway(inspectingStatus: 기본값 normal)로 상태가 바로 변경됨
       * 고객 동의를 받은 경우에는 putAway + inspectionStatus: consent 로 상태가 변경됨
       */
      return "입고 중";
    case "hold":
      return "전담매니저 확인 중";

    // 입고 완료 리스트(Admin)
    case "done":
      return "입고완료";

    // 입고 취소/회송 리스트(Admin)
    case "canceled":
      return "취소";
    case "returned":
      return "회송";

    default:
      return status;
  }
}

// 입고 & 검수 오류 사유
function getReceivingAndInspectionProblemString(
  problemStatus: ReceivingAndInspectionProblem,
  directInput: string
) {
  switch (problemStatus) {
    case "none":
      return "문제없음";
    case "quantityMismatch":
      return "수량 불일치";
    case "productMismatch":
      return "상품 불일치";
    case "productDamage":
      return "상품 데미지";
    case "tollProcessing":
      return "임가공 문제";
    case "other":
      return "기타";
    case "directInput":
      return directInput;
    default:
      return problemStatus;
  }
}

// 부자재 package category 한글로 변환
function getPackageCategoryName(packageCategory: PackageCategory) {
  if (packageCategory === "outside") return "외부포장";
  if (packageCategory === "buffer") return "완충재";
  if (packageCategory === "tape") return "테이프";

  return "-";
}

function getMaterialIdTag(
  packageCategory: PackageCategory | undefined,
  packageType: PackageType | undefined,
  provider: ProviderType
) {
  if (provider === "shipda") {
    switch (packageCategory) {
      case "outside": {
        if (packageType === "box") return "EX";
        if (packageType === "ecoBox") return "EF";
        if (packageType === "polybag") return "EP";
        return "-";
      }

      case "buffer": {
        if (packageType === "airCap") return "BA";
        if (packageType === "bubblePaper") return "BP";
        return "-";
      }

      case "tape": {
        if (packageType === "oppTape") return "TO";
        if (packageType === "craft") return "TK";
        return "-";
      }

      default: {
        return "-";
      }
    }
  } else {
    switch (packageCategory) {
      case "outside": {
        return "EC";
      }

      case "buffer": {
        return "BS";
      }

      case "tape": {
        return "TN";
      }

      default: {
        return "-";
      }
    }
  }
}

const PACKAGE_CATEGORY: PackageCategory[] = ["outside", "buffer", "tape"];

const PACKAGE_TYPE: PackageType[] = [
  "none",
  "directInput",
  "automaticSelection",
  "box",
  "ecoBox",
  "safetyBag",
  "kraftBag",
  "ldpeBag",
  "styrofoam",
  "polybag",
  "nonWovenSuitcase",
  "lamyBag",
  "airShell",
  "airCap",
  "airPad",
  "airPouch",
  "peelPack",
  "padPack",
  "cornStarchBuffer",
  "other",
  "blister",
  "geami",
  "bubblePaper",
  "oppTape",
  "craft",
];

const OUTSIDE_PACKAGE_TYPE: OutsidePackageType[] = [
  "directInput",
  "box",
  "ecoBox",
  "polybag",
];

const BUFFER_PACKAGE_TYPE: BufferPackageType[] = [
  "directInput",
  "airCap",
  "bubblePaper",
];

const TAPE_PACKAGE_TYPE: TapePackageType[] = [
  "directInput",
  "oppTape",
  "craft",
];

// 부자재 package type 한글로 변환
function getPackageTypeName(packageType: PackageType) {
  switch (packageType) {
    case "directInput":
      return "직접 입력";
    case "box":
      return "박스";
    case "polybag":
      return "폴리백";
    case "ecoBox":
      return "친환경박스";
    case "bubblePaper":
      return "친환경 버블페이퍼";
    case "airCap":
      return "에어캡";
    case "oppTape":
      return "OPP 테이프";
    case "craft":
      return "친환경 테이프";
    case "none":
      return "-";
    default:
      return packageType;
  }
}

function hasUploadFiles(fileList: (AttachmentFileItem | FileDataItem)[]) {
  const files = fileList.filter((file) => file.type === "file");

  return !!files.length;
}

// 박스 옵션 (사이즈 등) 을 창고 관리자가 상품에 맞춰 정하므로 기본 단위의 고정값으로 프론트에서 설정하기로 함.
// 50 : 기본박스, 51 : 폴리백, 53 : 에어캡, 55 : OPP 테이프
// 52 : 친환경 박스, 54 : 친환경 버블페이퍼, 56 : 친환경 테이프, 81 : 완충재 선택없음

const BASIC_PACKAGE_LIST: PackageTabItem = {
  outside: [
    {
      id: 50,
      name: "쉽다 기본박스",
      imageUrl: "/assets/images/mypage/fulfillment/box.png",
      provider: "shipda",
      packageCategory: "outside",
      packageType: "box",
      materialCode: "GB",
    },
    {
      id: 51,
      name: "쉽다 폴리백",
      imageUrl: "/assets/images/mypage/fulfillment/pollybag.png",
      provider: "shipda",
      packageCategory: "outside",
      packageType: "polybag",
      materialCode: "PB",
    },
  ],
  buffer: [
    {
      id: 53,
      name: "쉽다 에어캡",
      imageUrl: "/assets/images/mypage/fulfillment/aircap.png",
      provider: "shipda",
      packageCategory: "buffer",
      packageType: "airCap",
      materialCode: "AC",
    },
  ],
  tape: [
    {
      id: 55,
      name: "쉽다 OPP 테이프",
      imageUrl: "/assets/images/mypage/fulfillment/opptape.png",
      provider: "shipda",
      packageCategory: "tape",
      packageType: "oppTape",
      materialCode: "OP",
    },
  ],
};

const ECO_PACKAGE_LIST: PackageTabItem = {
  outside: [
    {
      id: 52,
      name: "쉽다 친환경 박스",
      imageUrl: "/assets/images/mypage/fulfillment/ecobox.png",
      provider: "shipda",
      packageCategory: "outside",
      packageType: "ecoBox",
      materialCode: "EB",
    },
  ],
  buffer: [
    {
      id: 54,
      name: "쉽다 친환경 버블페이퍼",
      imageUrl: "/assets/images/mypage/fulfillment/bubblepaper.png",
      provider: "shipda",
      packageCategory: "buffer",
      packageType: "bubblePaper",
      materialCode: "BP",
    },
  ],
  tape: [
    {
      id: 56,
      name: "쉽다 친환경 테이프",
      imageUrl: "/assets/images/mypage/fulfillment/ecotape.png",
      provider: "shipda",
      packageCategory: "tape",
      packageType: "craft",
      materialCode: "KT",
    },
  ],
};

const BUFFER_NONE_ITEM: PackageItem = {
  id: 81,
  name: "필요없음",
  imageUrl: "/assets/images/mypage/fulfillment/buffer-none.png",
  provider: "shipda",
  packageCategory: "buffer",
  packageType: "airCap",
  materialCode: "NU",
};

function getProviderName(provider: ProviderType | null) {
  if (!provider) return "";

  if (provider === "shipda") return "쉽다";
  if (provider === "customer") return "고객사";

  return "";
}

function getMaterialPackageType(materialPackageType: MaterialPackageType) {
  switch (materialPackageType) {
    case "basic":
      return "기본포장";
    case "eco":
      return "친환경포장";
    case "customize":
      return "커스터마이징";
    case "none":
      return "포장불필요";
    default:
      return "";
  }
}

/**
 * 수량이 가장 많은 상품이 대표상품. ㅇㅇ등으로 표기.
 */
function getFulfillmentItemTitle(
  items?: { sku?: { itemName?: string }; quantity?: number }[]
) {
  if (!items || !items.length) return "-";
  if (items.length === 1) return items[0].sku?.itemName;

  const maxQuantityItem = items.reduce((prev, cur) =>
    (prev.quantity ?? 0) > (cur.quantity ?? 0) ? prev : cur
  );

  return `${maxQuantityItem.sku?.itemName} 등`;
}

function toParcelCompanyLabel(parcelCompany?: FulfillmentParcelCompany) {
  switch (parcelCompany) {
    case "cj": {
      return "CJ대한통운";
    }
    case "hanjin": {
      return "한진택배";
    }
    case "post": {
      return "우체국택배";
    }
    case "daesin": {
      return "대신택배";
    }
    case "kunyoung": {
      return "건영택배";
    }
    case "chunil": {
      return "천일택배";
    }
    case "kd": {
      return "경동택배";
    }
    case "lotte":
      return "롯데택배";
    case "logen":
      return "로젠택배";
    case "ups":
      return "UPS";
    case "fedex":
      return "FEDEX";
    case "etc":
      return "기타";
    default: {
      return parcelCompany ?? "";
    }
  }
}

function toTruckCompanyLabel(truckCompany?: FulfillmentTruckCompany) {
  switch (truckCompany) {
    case "gogoVan": {
      return "고고밴";
    }
    case "happyLogistics": {
      return "해피로지스틱스";
    }
    case "sandy": {
      return "샌디";
    }
    case "gana": {
      return "가나물류";
    }
    default: {
      return "";
    }
  }
}

/** 출고 & 반품 > 화물차종을 반환 */
function toTruckTypeLabel(truckType?: CommonTruckType) {
  switch (truckType) {
    case "motorcycle": {
      return "오토바이";
    }
    case "damas": {
      return "다마스";
    }
    case "labo": {
      return "라보";
    }
    case "van": {
      return "밴";
    }
    case "1ton": {
      return "1톤 트럭";
    }
    case "1ton_wingbody": {
      return "1톤 윙바디 트럭";
    }
    case "ton1_2_and_ton1_4": {
      return "1.2톤&1.4톤 트럭";
    }
    case "ton2_5_and_ton3_5":
    case "ton3_5_and_ton2_5": {
      return "2.5톤&3.5톤 트럭";
    }
    case "ton2_5_and_ton3_5_wingbody": {
      return "2.5톤&3.5톤 윙바디 트럭";
    }
    case "5ton": {
      return "5톤 트럭";
    }
    case "5ton_rotor": {
      return "5톤 축차";
    }
    case "5ton_wingbody": {
      return "5톤 윙바디 트럭";
    }
    case "ton7_5": {
      return "7.5톤 트럭";
    }
    case "11ton": {
      return "11톤 트럭";
    }
    case "14ton": {
      return "14톤 트럭";
    }
    case "25ton": {
      return "25톤 트럭";
    }
    default: {
      return "";
    }
  }
}

/**
 * SKU를 사용자에게 보여주는 형식으로 반환 (앞에 S를 붙임)
 */
function getFormattedSingleSkuId(skuId?: number) {
  if (!skuId) return "";

  return `S${skuId}`;
}

/**
 * 그룹의 SKU ID를 사용자에게 보여주는 형식으로 변환 (앞에 G를 붙임)
 */
function getFormattedGroupSkuId(groupSkuId: number | undefined) {
  if (!groupSkuId) {
    return "";
  }

  return `G${groupSkuId}`;
}

/**
 * SKU 바코드에서 SKU ID를 반환
 */
function getSKUIdFromSKUBarcode(SKUBarcode: string) {
  const SKUId = SKUBarcode.replace("S", "");

  return isNaN(Number(SKUId)) ? undefined : Number(SKUId);
}

const getReceivingTypeToString = (receivingType: ReceivingType) => {
  switch (receivingType) {
    case "twoDay":
      return "2일 내 입고";
    case "oneDay":
      return "1일 내 입고";
    case "sameDay":
      return "당일 입고";
    default:
      return "-";
  }
};

/**
 * 반품 & 출고 > 택배사(화물차량)을 반환
 */
function getDeliveryName({
  deliveryType,
  parcelCompany,
  truckType,
}: {
  deliveryType?: ShippingDeliveryType;
  parcelCompany?: FulfillmentParcelCompany;
  truckType?: CommonTruckType;
}) {
  if (
    deliveryType === "parcel" ||
    deliveryType === "freight" ||
    deliveryType === "airExpress"
  ) {
    return toParcelCompanyLabel(parcelCompany);
  }

  if (deliveryType === "truck") {
    return toTruckTypeLabel(truckType);
  }

  return "";
}

/**
 * packings 리스트에서 invoiceNo 반환
 */
function getPackingsInvoiceNo({
  packings,
  isFullList,
  needsArray,
}: {
  packings: ShippingPacking[];
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (packings.length === 0) return "";

  if (packings.length === 1) return packings[0].invoiceNo ?? "";

  if (isFullList && needsArray) {
    return packings.map(({ invoiceNo }) => invoiceNo);
  }

  if (isFullList && !needsArray) {
    return packings.map(({ invoiceNo }) => invoiceNo).join(", ");
  }

  return `${packings[0].invoiceNo ?? ""} 외 ${packings.length - 1}건`;
}

/**
 * 송장번호(차량번호)를 반환
 */
function getDeliveryNumberOfShipping({
  deliveryType,
  packings,
  trucking,
  invoiceNo,
  isFullList,
  needsArray,
}: {
  deliveryType?: ShippingDeliveryType;
  packings?: ShippingPacking[];
  trucking?: ShippingTrucking;
  invoiceNo?: string;
  isFullList?: boolean;
  needsArray?: boolean;
}) {
  if (
    deliveryType === "parcel" ||
    deliveryType === "freight" ||
    deliveryType === "airExpress"
  ) {
    if (!packings) return "";

    if (packings.length === 0) {
      return invoiceNo ?? "";
    }

    return getPackingsInvoiceNo({ packings, isFullList, needsArray });
  }

  if (deliveryType === "truck") {
    return trucking?.truckNo ?? "";
  }

  return "";
}

function getParcelCompanyPhone(parcelCompany?: FulfillmentParcelCompany) {
  switch (parcelCompany) {
    case "cj":
      return "(1588-1255)";
    case "hanjin":
      return "(1588-0011)";
    case "kunyoung":
      return "(1661-7878)";
    case "daesin":
      return "(043-222-4582)";
    case "post":
      return "(1588-1300)";
    case "chunil":
      return "(1877-6606)";
    case "kd":
      return "(1899-5368)";
    case "ups":
      return "(1588-6886)";
    default:
      return "";
  }
}

function getStatusFromFailureInfo(
  failureInfo: ResponseFailureInfo | undefined
) {
  return failureInfo?.error?.split(": ")[1] ?? "";
}

/** shippingStatus 관련 에러 */
function getErrorMessageAsE4000({
  failureInfo,
}: {
  failureInfo: ResponseFailureInfo | undefined;
}) {
  const status = getStatusFromFailureInfo(failureInfo);

  return (
    {
      waiting: "정상적으로 출력되지 않은 송장(QR)입니다.",
      close: (
        // eslint-disable-next-line react/jsx-key
        <div style={{ color: COLOR.pointWarning }}>
          피킹/패킹이 필요없는 송장(QR)입니다.
          <br />
          출하존으로 이동시켜 주세요.
        </div>
      ),
      delivering: "배송 중인 송장(QR)입니다.",
      done: "배송이 완료된 송장(QR)입니다.",
      return: "반품된 송장(QR)입니다.",
      survey: "재고 조사 중인 송장(QR)입니다.",
    }[status] ?? "현재 작업 단계에 맞지 않은 송장(QR)입니다."
  );
}

/** wmsStatus 관련 에러 */
function getErrorMessageAsE4009({
  failureInfo,
}: {
  failureInfo: ResponseFailureInfo | undefined;
}) {
  const status = getStatusFromFailureInfo(failureInfo);

  return (
    {
      // 스캔 가능 상태 - 피킹 시작: waiting, picking
      packing:
        "피킹 작업이 완료되었으나, 패킹 작업이 완료되지 않은 송장(QR)입니다.",
      ready: (
        // eslint-disable-next-line react/jsx-key
        <div style={{ color: COLOR.pointWarning }}>
          피킹/패킹이 필요없는 송장(QR)입니다.
          <br />
          출하존으로 이동시켜 주세요.
        </div>
      ),
      done: "출하 완료된 송장(QR)입니다.",
      return: "반품된 송장(QR)입니다.",
      survey: "재고 조사 중인 송장(QR)입니다.",
    }[status] ?? "현재 작업 단계에 맞지 않은 송장(QR)입니다."
  );
}

function getNumberOfSKUType({
  items,
}: {
  items: { skuId: number }[] | undefined;
}) {
  return new Set((items ?? []).map((item) => item.skuId)).size;
}

const getSKUIdList = (items: { skuId: number }[]) => {
  const SKUIDList = new Set(
    items.map(({ skuId }) => getFormattedSingleSkuId(skuId))
  );

  return Array.from(SKUIDList).join(", ");
};

const getSKUQuantityList = (items: { quantity: number }[]) => {
  return items.map(({ quantity }) => quantity).join(", ");
};

function getSumOfSKUQuantity({
  items,
}: {
  items: { quantity: number }[] | undefined;
}) {
  return (items ?? []).reduce((prevSum, item) => {
    return prevSum + item.quantity;
  }, 0);
}

/**
 * @returns 포장 접두어 Tag가 추가된 포장 ID 반환
 */
const getFormattedMaterialId = (
  packageItem: Pick<
    MaterialItem,
    "id" | "packageCategory" | "packageType" | "provider"
  >
) => {
  const packageCategoryType = ["outside", "buffer", "tape"];

  const isInPackageCategory = packageCategoryType.some(
    (packageCategory) => packageCategory === packageItem.packageCategory
  );

  if (isInPackageCategory) {
    return `${getMaterialIdTag(
      packageItem.packageCategory,
      packageItem.packageType,
      packageItem.provider
    )}${packageItem.id}`;
  }

  return String(packageItem.id);
};

/**
 * @returns 포장 ID에서 접두어 Tag를 제외한 포장 ID 반환
 */
function removeMaterialIdPrefix(materialId: string | number | undefined) {
  if (!materialId) return materialId;

  if (typeof materialId === "number") return materialId;

  return materialId.replace(/EX|EF|EP|BA|BP|TO|TK|EC|BS|TN|-/gi, "");
}

export {
  getReceivingStatusString,
  getReceivingAndInspectionProblemString,
  getPackageCategoryName,
  getMaterialIdTag,
  PACKAGE_CATEGORY,
  PACKAGE_TYPE,
  OUTSIDE_PACKAGE_TYPE,
  BUFFER_PACKAGE_TYPE,
  TAPE_PACKAGE_TYPE,
  getPackageTypeName,
  hasUploadFiles,
  BASIC_PACKAGE_LIST,
  ECO_PACKAGE_LIST,
  BUFFER_NONE_ITEM,
  getProviderName,
  getMaterialPackageType,
  getFulfillmentItemTitle,
  toParcelCompanyLabel,
  toTruckCompanyLabel,
  toTruckTypeLabel,
  getFormattedSingleSkuId,
  getFormattedGroupSkuId,
  getSKUIdFromSKUBarcode,
  getReceivingTypeToString,
  getDeliveryName,
  getPackingsInvoiceNo,
  getDeliveryNumberOfShipping,
  getParcelCompanyPhone,
  getStatusFromFailureInfo,
  getErrorMessageAsE4000,
  getErrorMessageAsE4009,
  getNumberOfSKUType,
  getSKUIdList,
  getSKUQuantityList,
  getSumOfSKUQuantity,
  getFormattedMaterialId,
  removeMaterialIdPrefix,
};
