import { useCallback, useMemo } from "react";

import useNetworkPrint from "@sellernote/_shared/src/hooks/fulfillment/useNetworkPrint";
import SHIPPING_QUERY from "@sellernote/_shared/src/queries/fulfillment/SHIPPING_QUERY";
import Toast from "@sellernote/_sds-v1/src/components/Toast";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function usePrintInvoiceForAll(shippingId: number | undefined) {
  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfInvoicePrintingData,
    initConfirmModalStatus: initConfirmModalStatusOfGettingInvoicePrintingData,
  } = SHIPPING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => {
      print(res);
    },
  });

  const {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    resetPrint,
  } = useNetworkPrint();

  const ToastOfPrintingInvoice = useMemo(
    () => (
      <>
        {isRequestPrintingComplete && (
          <Toast
            message={`송장번호 인쇄 요청이 완료되었습니다.`}
            status="success"
            needIcon
            reset={resetPrint}
          />
        )}
      </>
    ),
    [isRequestPrintingComplete, resetPrint]
  );

  const printInvoice = useCallback(() => {
    if (!shippingId) {
      return;
    }

    getInvoicePrintingData({ shippingIds: [shippingId] });
  }, [getInvoicePrintingData, shippingId]);

  useScanActionBarcode({
    actionBarcode: "H_GET_INV_PRI",
    actionFn: initConfirmModalStatusOfGettingInvoicePrintingData,
  });
  useScanActionBarcode({
    actionBarcode: "PRI_ALL_INV",
    actionFn: printInvoice,
  });

  return {
    printInvoice,

    ResponseHandlerOfInvoicePrintingData,
    LoadingOfPrintingInvoice: LoadingToNetworkPrint,
    ToastOfPrintingInvoice,
  };
}
