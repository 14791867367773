import { useCallback, useMemo, useState } from "react";

import useNetworkPrint from "@sellernote/_shared/src/hooks/fulfillment/useNetworkPrint";
import PACKING_QUERY from "@sellernote/_shared/src/queries/fulfillment/PACKING_QUERY";
import Toast from "@sellernote/_sds-v1/src/components/Toast";

import useScanActionBarcode from "hooks/packing/useScanActionBarcode";

export default function usePrintInvoice() {
  const [invoiceNo, setInvoiceNo] = useState("");

  const {
    mutate: getInvoicePrintingData,
    ResponseHandler: ResponseHandlerOfGettingInvoicePrintingData,
    initConfirmModalStatus: initConfirmModalStatusOfGettingInvoicePrintingData,
  } = PACKING_QUERY.useGetInvoicePrintingData({
    onSuccess: (res) => {
      print(res);
    },
  });

  const {
    print,
    LoadingToNetworkPrint,
    isRequestPrintingComplete,
    resetPrint,
  } = useNetworkPrint();

  const ToastOfPrintingInvoice = useMemo(
    () => (
      <>
        {isRequestPrintingComplete && (
          <Toast
            message={`송장번호 ${invoiceNo} 인쇄 요청이 완료되었습니다.`}
            status="success"
            needIcon
            reset={resetPrint}
          />
        )}
      </>
    ),
    [invoiceNo, isRequestPrintingComplete, resetPrint]
  );

  const printInvoice = useCallback(
    (invoiceNo: string) => () => {
      getInvoicePrintingData({ pathParams: { invoiceNo } });
      invoiceNo && setInvoiceNo(invoiceNo);
    },
    [getInvoicePrintingData]
  );

  useScanActionBarcode({
    actionBarcode: "H_GET_INV_PRI",
    actionFn: initConfirmModalStatusOfGettingInvoicePrintingData,
  });

  return {
    printInvoice,

    ResponseHandlerOfGettingInvoicePrintingData,
    LoadingOfPrintingInvoice: LoadingToNetworkPrint,
    ToastOfPrintingInvoice,
  };
}
