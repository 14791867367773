import { FC, useCallback, useEffect, useState } from "react";

type ToastStatus = "success" | "failure";

type ToastResetProps = () => void;

interface ToastProps {
  status: ToastStatus;
  message: React.ReactNode;
  reset: ToastResetProps;
  needIcon?: boolean;
  className?: string;
}

type ToastComponent = FC<ToastProps>;

function useToast({
  reset,
  disablesAutoClose,
}: {
  /**
   * Toast가 정해진 시간이 지나 사라질때 실행되는 callback
   */
  reset: () => void;
  /**
   * Toast가 자동으로 닫히지 않게 함(ex. 토스트에 닫기버튼이 있을때)
   */
  disablesAutoClose?: boolean;
}) {
  const [isVisibleToast, setIsVisibleToast] = useState(true);

  const disappearToast = useCallback(() => {
    setTimeout(() => {
      setIsVisibleToast(false);

      reset();
    }, 5000);
  }, [reset]);

  useEffect(() => {
    if (disablesAutoClose) {
      return;
    }

    disappearToast();
  }, [disablesAutoClose, disappearToast]);

  return { isVisibleToast, setIsVisibleToast };
}

export default useToast;

export type { ToastProps, ToastStatus, ToastComponent, ToastResetProps };
