import { CountryCode, StateCode } from "../../types/common/address";
import {
  ExpandableTableColumnFilterOptionList,
  SearchSelectorOptionList,
  TableColumnFilterOptionList,
} from "../../types/fulfillment/common";
import {
  FulfillmentChanel,
  FulfillmentParcelCompany,
} from "../../types/fulfillment/fulfillment";
import {
  CreatedFromType,
  ShippingDeliveryType,
  ShippingStatus,
} from "../../types/fulfillment/shipping";

const SHIPPING_DELIVERY_TYPE: Record<ShippingDeliveryType, string> = {
  parcel: "일반택배",
  freight: "화물택배",
  truck: "화물차량",
  selfCollect: "직접수령",
  airExpress: "항공특송",
  oceanExpress: "해상특송",
};

const CREATED_FROM_TYPE: Record<CreatedFromType, string> = {
  user: "직접등록",
  oms: "주문연동",
  excel: "대량등록",
};

/**
 * 국내 해외 판매채널
 * - 국내 판매채널 id 범위: 1~100
 * - 해외 판매채널 id 범위: 100~
 * - id 998 B2B는 국내 판매채널에만 해당
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const MALL_OPTION_TYPE: FulfillmentChanel[] = [
  {
    id: 1,
    name: "auction",
    korName: "옥션",
  },
  {
    id: 2,
    name: "interpark",
    korName: "인터파크",
  },
  {
    id: 3,
    name: "hyundaiHomeShopping",
    korName: "현대홈쇼핑",
  },
  {
    id: 4,
    name: "10x10",
    korName: "텐바이텐",
  },
  {
    id: 5,
    name: "eleven",
    korName: "11번가",
  },
  {
    id: 6,
    name: "1300k",
    korName: "천삼백케이",
  },
  {
    id: 7,
    name: "AKmall",
    korName: "AK몰",
  },
  {
    id: 8,
    name: "AKDepartmant",
    korName: "AK백화점",
  },
  {
    id: 9,
    name: "CJHomeShopping",
    korName: "CJ홈쇼핑",
  },
  {
    id: 10,
    name: "GSHomeShopping",
    korName: "GS홈쇼핑",
  },
  {
    id: 11,
    name: "NSHomeShopping",
    korName: "NS홈쇼핑",
  },
  {
    id: 12,
    name: "SKStoa",
    korName: "SK스토아",
  },
  {
    id: 13,
    name: "WShopping",
    korName: "W쇼핑",
  },
  {
    id: 14,
    name: "YES24",
    korName: "예스24",
  },
  {
    id: 15,
    name: "godoMall",
    korName: "고도몰",
  },
  {
    id: 16,
    name: "naverPay",
    korName: "네이버페이",
  },
  {
    id: 17,
    name: "lotteMart",
    korName: "롯데마트",
  },
  {
    id: 18,
    name: "lotteDepartmant",
    korName: "롯데백화점몰",
  },
  {
    id: 19,
    name: "lotteIMall",
    korName: "롯데아이몰",
  },
  {
    id: 20,
    name: "lotteOn",
    korName: "롯데온",
  },
  {
    id: 21,
    name: "musinsaStore",
    korName: "무신사스토어",
  },
  {
    id: 22,
    name: "smartStore",
    korName: "스마트스토어",
  },
  {
    id: 23,
    name: "styleShare",
    korName: "스타일쉐어",
  },
  {
    id: 24,
    name: "shinsegaeMall",
    korName: "신세계닷컴",
  },
  {
    id: 25,
    name: "ohnu",
    korName: "오늘의집",
  },
  {
    id: 26,
    name: "weMakePrice",
    korName: "위메프",
  },
  {
    id: 27,
    name: "emart",
    korName: "이마트",
  },
  {
    id: 28,
    name: "gmarket",
    korName: "지마켓",
  },
  {
    id: 29,
    name: "kakaoStore",
    korName: "카카오톡 스토어",
  },
  {
    id: 30,
    name: "cafe24",
    korName: "카페24",
  },
  {
    id: 31,
    name: "coupang",
    korName: "쿠팡",
  },
  {
    id: 32,
    name: "hnsMall",
    korName: "홈앤쇼핑",
  },
  {
    id: 33,
    name: "homePlus",
    korName: "홈플러스",
  },
  {
    id: 34,
    name: "imweb",
    korName: "아임웹",
  },
  {
    id: 100,
    name: "Amazon",
    korName: "아마존",
  },
  {
    id: 101,
    name: "BLIBLI",
    korName: "블리블리",
  },
  {
    id: 102,
    name: "Bukalapak",
    korName: "부칼라팍",
  },
  {
    id: 103,
    name: "Catch",
    korName: "캐치",
  },
  {
    id: 104,
    name: "Central",
    korName: "센트럴",
  },
  {
    id: 105,
    name: "Ebay",
    korName: "이베이",
  },
  {
    id: 106,
    name: "Etsy",
    korName: "엣시",
  },
  {
    id: 107,
    name: "Flipkart",
    korName: "플립카트",
  },
  {
    id: 108,
    name: "Fnac",
    korName: "프낙",
  },
  {
    id: 109,
    name: "IshopChangi",
    korName: "창이",
  },
  {
    id: 110,
    name: "JDCentral",
    korName: "제이디센트럴",
  },
  {
    id: 111,
    name: "JDID",
    korName: "제이디아이디",
  },
  {
    id: 112,
    name: "Jumia",
    korName: "주미아",
  },
  {
    id: 113,
    name: "Lazada",
    korName: "라자다",
  },
  {
    id: 114,
    name: "MetroDeal",
    korName: "메트로딜",
  },
  {
    id: 115,
    name: "Nykaa",
    korName: "니카",
  },
  {
    id: 116,
    name: "OnBuy",
    korName: "온버이",
  },
  {
    id: 117,
    name: "OZSale",
    korName: "오즈세일",
  },
  {
    id: 118,
    name: "PGMall",
    korName: "피지몰",
  },
  {
    id: 119,
    name: "PrestoMall",
    korName: "프레스토몰",
  },
  {
    id: 120,
    name: "QooTen",
    korName: "큐텐",
  },
  {
    id: 121,
    name: "Rakuten",
    korName: "라쿠텐",
  },
  {
    id: 122,
    name: "Shopee",
    korName: "쇼피",
  },
  {
    id: 123,
    name: "TheIconic",
    korName: "아이코닉",
  },
  {
    id: 124,
    name: "TIKI",
    korName: "티키",
  },
  {
    id: 125,
    name: "Tokopedia",
    korName: "토코피디아",
  },
  {
    id: 126,
    name: "Youbeli",
    korName: "유벨리",
  },
  {
    id: 127,
    name: "Zalora",
    korName: "자로라",
  },
  {
    id: 128,
    name: "91APP",
    korName: "91APP",
  },
  {
    id: 129,
    name: "Haravan",
    korName: "하라반",
  },
  {
    id: 130,
    name: "Magento",
    korName: "마젠토",
  },
  {
    id: 131,
    name: "Opencart",
    korName: "오픈카트",
  },
  {
    id: 132,
    name: "PrestaShop",
    korName: "프레스타샵",
  },
  {
    id: 133,
    name: "CommerceCloud",
    korName: "커머스클라우드",
  },
  {
    id: 134,
    name: "Shopify",
    korName: "쇼피파이",
  },
  {
    id: 135,
    name: "ShopifyPlus",
    korName: "쇼피파이플러스",
  },
  {
    id: 136,
    name: "WooCommerce",
    korName: "우커머스",
  },
  {
    id: 998,
    name: "B2B",
    korName: "B2B",
  },
  {
    id: 999,
    name: "etc",
    korName: "기타",
  },
];

/** ID 100 부터 해외 판매채널 */
const FIRST_OVERSEAS_MALL_ID = 100;
/** B2B 판매채널 (국내에서만 사용) */
const B2B_MALL_ID = 998;
/** 기타 판매채널 */
const ETC_MALL_ID = 999;

/**
 * 국내 판매채널 옵션 리스트
 * - 국내 판매채널 id 범위: 1~100
 * - id 998 B2B는 국내 판매채널에만 해당
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const DOMESTIC_MALL_OPTION_LIST: TableColumnFilterOptionList<number> =
  MALL_OPTION_TYPE.filter(
    (mall) =>
      mall.id < FIRST_OVERSEAS_MALL_ID ||
      mall.id === B2B_MALL_ID ||
      mall.id === ETC_MALL_ID
  ).map((mall) => ({ label: mall.korName, value: mall.id }));

/**
 * 해외 판매채널 옵션 리스트
 * - 해외 판매채널 id 범위: 100~
 * - id 999 기타는 국내 해외 판매채널 모두 해당
 */
const OVERSEAS_MALL_OPTION_LIST: TableColumnFilterOptionList<number> =
  MALL_OPTION_TYPE.filter(
    (mall) => mall.id >= FIRST_OVERSEAS_MALL_ID && mall.id !== B2B_MALL_ID
  ).map((mall) => ({ label: mall.korName, value: mall.id }));

/** 출고현황(전체 탭) 옵션 리스트 */
const SHIPPING_STATUS_OPTION_LIST_FOR_ALL_TAB: TableColumnFilterOptionList<
  ShippingStatus | ShippingStatus[]
> = [
  { label: "요청 확인 중", value: "waiting" },
  { label: "작업 중", value: "inProgress" },
  { label: "취소", value: "cancel" },
  { label: "출고완료", value: ["close", "delivering", "done"] },
  { label: "출고완료(반품)", value: "return" },
];

/** 출고현황(출고신청/취소 탭) 옵션 리스트 */
const SHIPPING_STATUS_OPTION_LIST_FOR_REQUEST_TAB: TableColumnFilterOptionList<ShippingStatus> =
  [
    { label: "요청 확인 중", value: "waiting" },
    { label: "작업 중", value: "inProgress" },
    { label: "취소", value: "cancel" },
  ];

/** 출고현황(출고완료 탭) 옵션 리스트 */
const SHIPPING_STATUS_OPTION_LIST_FOR_COMPLETED_TAB: TableColumnFilterOptionList<
  ShippingStatus | ShippingStatus[]
> = [
  { label: "출고완료", value: ["close", "delivering", "done"] },
  { label: "출고완료(반품)", value: "return" },
];

/** 국내 운송수단 옵션 리스트 */
const DOMESTIC_DELIVERY_TYPE_OPTION_LIST: TableColumnFilterOptionList<ShippingDeliveryType> =
  [
    { label: "일반택배", value: "parcel" },
    { label: "화물택배", value: "freight" },
    { label: "화물차량", value: "truck" },
    { label: "직접수령", value: "selfCollect" },
  ];

/** 해외 운송수단 옵션 리스트 */
const OVERSEAS_DELIVERY_TYPE_OPTION_LIST: TableColumnFilterOptionList<ShippingDeliveryType> =
  [
    { label: "항공 특송", value: "airExpress" },
    { label: "해상 특송", value: "oceanExpress" },
  ];

/** 택배사(화물차종) 옵션 리스트 */
const DELIVERY_NAME_OPTION_LIST: ExpandableTableColumnFilterOptionList = [
  {
    type: "root",
    label: "택배",
    options: [
      {
        type: "internal",
        label: "일반택배",
        options: [
          {
            type: "leaf",
            label: "CJ대한통운",
            value: "cj",
          },
          {
            type: "leaf",
            label: "한진택배",
            value: "hanjin",
          },
        ],
      },
      {
        type: "internal",
        label: "화물택배",
        options: [
          {
            type: "leaf",
            label: "대신택배",
            value: "daesin",
          },
          {
            type: "leaf",
            label: "건영택배",
            value: "kunyoung",
          },
          {
            type: "leaf",
            label: "천일택배",
            value: "chunil",
          },
          {
            type: "leaf",
            label: "경동택배",
            value: "kd",
          },
          {
            type: "leaf",
            label: "기타",
            value: "etc",
          },
        ],
      },
    ],
  },
  {
    type: "root",
    label: "화물차량",
    options: [
      { type: "leaf", label: "오토바이", value: "motorcycle" },
      { type: "leaf", label: "다마스", value: "damas" },
      { type: "leaf", label: "라보", value: "labo" },
      { type: "leaf", label: "벤", value: "van" },
      {
        type: "internal",
        label: "트럭",
        options: [
          {
            type: "leaf",
            label: "1톤 트럭",
            value: "1ton",
          },
          {
            type: "leaf",
            label: "1.2톤 & 1.4톤 트럭",
            value: "ton1_2_and_ton1_4",
          },
          {
            type: "leaf",
            label: "2.5톤 & 3.5톤 트럭",
            value: "ton2_5_and_ton3_5",
          },
          { type: "leaf", label: "5톤 트럭", value: "5ton" },
          { type: "leaf", label: "7.5톤 트럭", value: "ton7_5" },
          { type: "leaf", label: "11톤 트럭", value: "11ton" },
          { type: "leaf", label: "14톤 트럭", value: "14ton" },
          { type: "leaf", label: "25톤 트럭", value: "25ton" },
        ],
      },
      {
        type: "internal",
        label: "윙바디",
        options: [
          {
            type: "leaf",
            label: "1톤 윙바디 트럭",
            value: "1ton_wingbody",
          },
          {
            type: "leaf",
            label: "2.5톤 & 3.5톤 윙바디 트럭",
            value: "ton2_5_and_ton3_5_wingbody",
          },
          {
            type: "leaf",
            label: "5톤 윙바디 트럭",
            value: "5ton_wingbody",
          },
        ],
      },
      {
        type: "internal",
        label: "축차",
        options: [{ type: "leaf", label: "5톤 축차", value: "5ton_rotor" }],
      },
    ],
  },
];

/** 국가 코드 */
const COUNTRY_CODE_TYPE: Record<CountryCode, string> = {
  KR: "한국",
  CN: "중국",
  AF: "아프카니스탄",
  AM: "아르메니아",
  AZ: "아제르바이잔",
  BD: "방글라데시",
  BN: "브루나이",
  BT: "부탄",
  HK: "홍콩",
  ID: "인도네시아",
  IN: "인도",
  JP: "일본",
  KG: "키르기스스탄",
  KH: "캄보디아",
  KZ: "카자흐스탄",
  LA: "라오스",
  LK: "스리랑카",
  MM: "미얀마",
  MN: "몽골",
  MO: "마카오",
  MV: "몰디브",
  MY: "말레이시아",
  NP: "네팔",
  PH: "필리핀",
  PK: "파키스탄",
  SG: "싱가포르",
  TH: "태국",
  TJ: "타지키스탄",
  TL: "동티모르",
  TM: "투르크메니스탄",
  TP: "티모르",
  TW: "대만",
  UZ: "우즈베키스탄",
  VN: "베트남",
  AE: "아랍에미리트 연합",
  BH: "바레인",
  EG: "이집트",
  IL: "이스라엘",
  IQ: "이라크",
  IR: "이란",
  DZ: "알제리",
  JO: "요르단",
  KW: "쿠웨이트",
  LB: "레바논",
  LY: "리비아",
  MA: "모로코",
  MR: "모리타니",
  NT: "중립지대",
  OM: "오만",
  PS: "팔레스타인 해방기구",
  QA: "카타르",
  SA: "사우디아라비아",
  SD: "수단",
  SY: "시리아",
  TN: "튀니지",
  YD: "남예멘",
  YE: "예멘",
  AD: "안도라",
  AL: "알바니아",
  AT: "오스트리아",
  BA: "보스니아-헤르체고비나",
  BE: "벨기에",
  BG: "불가리아",
  BY: "벨라루스",
  CH: "스위스",
  CS: "세르비아와 몬테네그로",
  CY: "키프로스",
  CZ: "체코",
  DE: "독일",
  DK: "덴마크",
  EE: "에스토니아",
  ES: "스페인",
  FI: "핀란드",
  FO: "파로에 군도",
  FR: "프랑스",
  FX: "프랑스 메트로폴리탄",
  GB: "영국",
  GE: "조지아",
  GG: "건지",
  GI: "지브롤터",
  GR: "그리스",
  GS: "남조지아 & 남샌드위치 군도",
  HR: "크로아티아",
  HU: "헝가리",
  IE: "아일랜드",
  IM: "맨섬",
  IS: "아이슬란드",
  IT: "이탈리아",
  JE: "저지",
  LI: "리히텐슈타인",
  LT: "리투아니아",
  LU: "룩셈부르크",
  LV: "라트비아",
  MC: "모나코",
  MD: "몰도바",
  ME: "몬테네그로",
  MF: "세인트 마틴",
  MK: "마케도니아",
  MT: "몰타",
  NL: "네덜란드",
  NO: "노르웨이",
  PL: "폴란드",
  PT: "포르투갈",
  RO: "루마니아",
  RS: "세르비아공화국",
  RU: "러시아",
  SE: "스웨덴",
  SI: "슬로베니아",
  SJ: "스발바르 제도",
  SK: "슬로바키아",
  SM: "산마리노",
  SU: "소련",
  TR: "터키",
  UA: "우크라이나",
  VA: "교황청",
  YM: "마케도니아",
  YU: "유고슬라비아",
  CA: "캐나다",
  US: "미국",
  AG: "앤티가바부다",
  AI: "앵귈라",
  AN: "네덜란드 열도",
  AR: "아르헨티나",
  AW: "아루바",
  BB: "바베이도스",
  BL: "세인트 바르탤르미",
  BM: "버뮤다",
  BO: "볼리비아",
  BQ: "영령 안탁",
  BR: "브라질",
  BS: "바하마",
  BZ: "벨리즈",
  CL: "칠레",
  CO: "콜롬비아",
  CR: "코스타리카",
  CU: "쿠바",
  CW: "큐라소",
  DM: "도미니카",
  DO: "도미니카 공화국",
  EC: "에콰도르",
  FK: "포클랜드 군도",
  GD: "그레나다",
  GF: "불령 가이아나",
  GL: "그린랜드",
  GP: "과들루프",
  GT: "과테말라",
  GY: "가이아나",
  HN: "온두라스",
  HT: "아이티",
  JM: "자메이카",
  KN: "세인트 키츠 네비스",
  KY: "영령 캐이맨 군도",
  LC: "세인트 루시아",
  MI: "미드웨이 군도",
  MQ: "마르티니크",
  MS: "몬트세랫",
  MX: "멕시코",
  NI: "니카라과",
  PA: "파나마",
  PE: "페루",
  PM: "세인트 피레 미켈론",
  PR: "푸에르토리코",
  PY: "파라과이",
  PZ: "파나마운하지역",
  SR: "수리남",
  SV: "엘살바도르",
  TC: "투르크 & 카이코스 군도",
  TT: "트리니다드 토바고",
  UY: "우루과이",
  VC: "세인트 빈센트 그레나딘",
  VE: "베네수엘라",
  VG: "영령 버진아일랜드",
  VI: "미령 버진군도",
  AO: "앙골라",
  BF: "부르키나파소",
  BI: "부룬디",
  BJ: "베냉",
  BV: "부베 섬",
  BW: "보츠와나",
  CD: "콩고 민주공화국",
  CF: "중앙아프리카공화국",
  CG: "콩고",
  CI: "코트디부아르",
  CM: "카메룬",
  CV: "카보 베르데",
  DJ: "지부티",
  EH: "서사하라",
  ER: "에리트레아",
  ET: "에티오피아",
  GA: "가봉",
  GH: "가나",
  GM: "감비아",
  GN: "기니",
  GQ: "적도 기니",
  GW: "기니비사우",
  IO: "영령 인도양",
  KE: "케냐",
  KM: "코모로",
  LR: "라이베리아",
  LS: "레소토",
  MG: "마다가스카르",
  ML: "말리",
  MU: "모리셔스",
  MW: "말라위",
  MZ: "모잠비크",
  NA: "나미비아",
  NE: "니제르",
  NG: "나이지리아",
  RE: "불령 리유니온,코모도 제도",
  RW: "르완다",
  SC: "세이셸",
  SH: "세인트 헬레나",
  SL: "시에라리온",
  SN: "세네갈",
  SO: "소말리아",
  SS: "사우스수단",
  ST: "상투메 프린스페",
  SZ: "스와질란드",
  TD: "차드",
  TF: "불령 남부지역",
  TG: "토고",
  TZ: "탄자니아",
  UG: "우간다",
  YT: "마요트",
  ZA: "남아프리카 공화국",
  ZM: "잠비아",
  ZR: "자이르",
  ZW: "짐바브웨",
  AQ: "남극 대륙",
  AS: "아메리칸 사모아",
  AU: "호주",
  CC: "코코스 군도",
  CK: "쿡 아일랜드",
  CT: "캔톤아일랜드",
  CX: "크리스마스 아일랜드",
  FJ: "피지",
  FM: "미크로네시아",
  GU: "괌",
  HM: "허드 앤 맥도날드 군도",
  JT: "존스턴섬",
  KI: "키리바시",
  MH: "마셜제도",
  MP: "북마리아나 군도",
  NC: "누벨칼레도니",
  NF: "노폴크 아일랜드",
  NH: "뉴헤브리디스",
  NQ: "드롬힝 마우드랜드",
  NR: "나우루",
  NU: "니우에",
  NZ: "뉴질랜드",
  PC: "퍼시픽제도（미령）",
  PF: "프랑스령 폴리네시아",
  PG: "파푸아 뉴기니",
  PN: "핏케언섬",
  PU: "퍼시픽 아일랜드",
  PW: "팔라우",
  SB: "솔로몬 제도",
  TK: "토켈라우",
  TO: "통가",
  TV: "투발루",
  UM: "마이너 아우틀링 합중국 군도",
  VU: "바누아투",
  WF: "왈라스 & 퓨투나",
  WK: "웨이크 아일랜드",
  WS: "사모아",
  KV: "코소보",
};

/** 국가 옵션 리스트 */
const COUNTRY_OPTION_LIST: SearchSelectorOptionList<CountryCode> = [
  { label: "한국(KR)", value: "KR" },
  { label: "중국(CN)", value: "CN" },
  { label: "아프카니스탄(AF)", value: "AF" },
  { label: "아르메니아(AM)", value: "AM" },
  { label: "아제르바이잔(AZ)", value: "AZ" },
  { label: "방글라데시(BD)", value: "BD" },
  { label: "브루나이(BN)", value: "BN" },
  { label: "부탄(BT)", value: "BT" },
  { label: "홍콩(HK)", value: "HK" },
  { label: "인도네시아(ID)", value: "ID" },
  { label: "인도(IN)", value: "IN" },
  { label: "일본(JP)", value: "JP" },
  { label: "키르기스스탄(KG)", value: "KG" },
  { label: "캄보디아(KH)", value: "KH" },
  { label: "카자흐스탄(KZ)", value: "KZ" },
  { label: "라오스(LA)", value: "LA" },
  { label: "스리랑카(LK)", value: "LK" },
  { label: "미얀마(MM)", value: "MM" },
  { label: "몽골(MN)", value: "MN" },
  { label: "마카오(MO)", value: "MO" },
  { label: "몰디브(MV)", value: "MV" },
  { label: "말레이시아(MY)", value: "MY" },
  { label: "네팔(NP)", value: "NP" },
  { label: "필리핀(PH)", value: "PH" },
  { label: "파키스탄(PK)", value: "PK" },
  { label: "싱가포르(SG)", value: "SG" },
  { label: "태국(TH)", value: "TH" },
  { label: "타지키스탄(TJ)", value: "TJ" },
  { label: "동티모르(TL)", value: "TL" },
  { label: "투르크메니스탄(TM)", value: "TM" },
  { label: "티모르(TP)", value: "TP" },
  { label: "대만(TW)", value: "TW" },
  { label: "우즈베키스탄(UZ)", value: "UZ" },
  { label: "베트남(VN)", value: "VN" },
  { label: "아랍에미리트 연합(AE)", value: "AE" },
  { label: "바레인(BH)", value: "BH" },
  { label: "이집트(EG)", value: "EG" },
  { label: "이스라엘(IL)", value: "IL" },
  { label: "이라크(IQ)", value: "IQ" },
  { label: "이란(IR)", value: "IR" },
  { label: "알제리(DZ)", value: "DZ" },
  { label: "요르단(JO)", value: "JO" },
  { label: "쿠웨이트(KW)", value: "KW" },
  { label: "레바논(LB)", value: "LB" },
  { label: "리비아(LY)", value: "LY" },
  { label: "모로코(MA)", value: "MA" },
  { label: "모리타니(MR)", value: "MR" },
  { label: "중립지대(NT)", value: "NT" },
  { label: "오만(OM)", value: "OM" },
  { label: "팔레스타인 해방기구(PS)", value: "PS" },
  { label: "카타르(QA)", value: "QA" },
  { label: "사우디아라비아(SA)", value: "SA" },
  { label: "수단(SD)", value: "SD" },
  { label: "시리아(SY)", value: "SY" },
  { label: "튀니지(TN)", value: "TN" },
  { label: "남예멘(YD)", value: "YD" },
  { label: "예멘(YE)", value: "YE" },
  { label: "안도라(AD)", value: "AD" },
  { label: "알바니아(AL)", value: "AL" },
  { label: "오스트리아(AT)", value: "AT" },
  { label: "보스니아-헤르체고비나(BA)", value: "BA" },
  { label: "벨기에(BE)", value: "BE" },
  { label: "불가리아(BG)", value: "BG" },
  { label: "벨라루스(BY)", value: "BY" },
  { label: "스위스(CH)", value: "CH" },
  { label: "세르비아와 몬테네그로(CS)", value: "CS" },
  { label: "키프로스(CY)", value: "CY" },
  { label: "체코(CZ)", value: "CZ" },
  { label: "독일(DE)", value: "DE" },
  { label: "덴마크(DK)", value: "DK" },
  { label: "에스토니아(EE)", value: "EE" },
  { label: "스페인(ES)", value: "ES" },
  { label: "핀란드(FI)", value: "FI" },
  { label: "파로에 군도(FO)", value: "FO" },
  { label: "프랑스(FR)", value: "FR" },
  { label: "프랑스 메트로폴리탄(FX)", value: "FX" },
  { label: "영국(GB)", value: "GB" },
  { label: "조지아(GE)", value: "GE" },
  { label: "건지(GG)", value: "GG" },
  { label: "지브롤터(GI)", value: "GI" },
  { label: "그리스(GR)", value: "GR" },
  { label: "남조지아 & 남샌드위치 군도(GS)", value: "GS" },
  { label: "크로아티아(HR)", value: "HR" },
  { label: "헝가리(HU)", value: "HU" },
  { label: "아일랜드(IE)", value: "IE" },
  { label: "맨섬(IM)", value: "IM" },
  { label: "아이슬란드(IS)", value: "IS" },
  { label: "이탈리아(IT)", value: "IT" },
  { label: "저지(JE)", value: "JE" },
  { label: "리히텐슈타인(LI)", value: "LI" },
  { label: "리투아니아(LT)", value: "LT" },
  { label: "룩셈부르크(LU)", value: "LU" },
  { label: "라트비아(LV)", value: "LV" },
  { label: "모나코(MC)", value: "MC" },
  { label: "몰도바(MD)", value: "MD" },
  { label: "몬테네그로(ME)", value: "ME" },
  { label: "세인트 마틴(MF)", value: "MF" },
  { label: "마케도니아(MK)", value: "MK" },
  { label: "몰타(MT)", value: "MT" },
  { label: "네덜란드(NL)", value: "NL" },
  { label: "노르웨이(NO)", value: "NO" },
  { label: "폴란드(PL)", value: "PL" },
  { label: "포르투갈(PT)", value: "PT" },
  { label: "루마니아(RO)", value: "RO" },
  { label: "세르비아공화국(RS)", value: "RS" },
  { label: "러시아(RU)", value: "RU" },
  { label: "스웨덴(SE)", value: "SE" },
  { label: "슬로베니아(SI)", value: "SI" },
  { label: "스발바르 제도(SJ)", value: "SJ" },
  { label: "슬로바키아(SK)", value: "SK" },
  { label: "산마리노(SM)", value: "SM" },
  { label: "소련(SU)", value: "SU" },
  { label: "터키(TR)", value: "TR" },
  { label: "우크라이나(UA)", value: "UA" },
  { label: "교황청(VA)", value: "VA" },
  { label: "마케도니아(YM)", value: "YM" },
  { label: "유고슬라비아(YU)", value: "YU" },
  { label: "캐나다(CA)", value: "CA" },
  { label: "미국(US)", value: "US" },
  { label: "앤티가바부다(AG)", value: "AG" },
  { label: "앵귈라(AI)", value: "AI" },
  { label: "네덜란드 열도(AN)", value: "AN" },
  { label: "아르헨티나(AR)", value: "AR" },
  { label: "아루바(AW)", value: "AW" },
  { label: "바베이도스(BB)", value: "BB" },
  { label: "세인트 바르탤르미(BL)", value: "BL" },
  { label: "버뮤다(BM)", value: "BM" },
  { label: "볼리비아(BO)", value: "BO" },
  { label: "영령 안탁(BQ)", value: "BQ" },
  { label: "브라질(BR)", value: "BR" },
  { label: "바하마(BS)", value: "BS" },
  { label: "벨리즈(BZ)", value: "BZ" },
  { label: "칠레(CL)", value: "CL" },
  { label: "콜롬비아(CO)", value: "CO" },
  { label: "코스타리카(CR)", value: "CR" },
  { label: "쿠바(CU)", value: "CU" },
  { label: "큐라소(CW)", value: "CW" },
  { label: "도미니카(DM)", value: "DM" },
  { label: "도미니카 공화국(DO)", value: "DO" },
  { label: "에콰도르(EC)", value: "EC" },
  { label: "포클랜드 군도(FK)", value: "FK" },
  { label: "그레나다(GD)", value: "GD" },
  { label: "불령 가이아나(GF)", value: "GF" },
  { label: "그린랜드(GL)", value: "GL" },
  { label: "과들루프(GP)", value: "GP" },
  { label: "과테말라(GT)", value: "GT" },
  { label: "가이아나(GY)", value: "GY" },
  { label: "온두라스(HN)", value: "HN" },
  { label: "아이티(HT)", value: "HT" },
  { label: "자메이카(JM)", value: "JM" },
  { label: "세인트 키츠 네비스(KN)", value: "KN" },
  { label: "영령 캐이맨 군도(KY)", value: "KY" },
  { label: "세인트 루시아(LC)", value: "LC" },
  { label: "미드웨이 군도(MI)", value: "MI" },
  { label: "마르티니크(MQ)", value: "MQ" },
  { label: "몬트세랫(MS)", value: "MS" },
  { label: "멕시코(MX)", value: "MX" },
  { label: "니카라과(NI)", value: "NI" },
  { label: "파나마(PA)", value: "PA" },
  { label: "페루(PE)", value: "PE" },
  { label: "세인트 피레 미켈론(PM)", value: "PM" },
  { label: "푸에르토리코(PR)", value: "PR" },
  { label: "파라과이(PY)", value: "PY" },
  { label: "파나마운하지역(PZ)", value: "PZ" },
  { label: "수리남(SR)", value: "SR" },
  { label: "엘살바도르(SV)", value: "SV" },
  { label: "투르크 & 카이코스 군도(TC)", value: "TC" },
  { label: "트리니다드 토바고(TT)", value: "TT" },
  { label: "우루과이(UY)", value: "UY" },
  { label: "세인트 빈센트 그레나딘(VC)", value: "VC" },
  { label: "베네수엘라(VE)", value: "VE" },
  { label: "영령 버진아일랜드(VG)", value: "VG" },
  { label: "미령 버진군도(VI)", value: "VI" },
  { label: "앙골라(AO)", value: "AO" },
  { label: "부르키나파소(BF)", value: "BF" },
  { label: "부룬디(BI)", value: "BI" },
  { label: "베냉(BJ)", value: "BJ" },
  { label: "부베 섬(BV)", value: "BV" },
  { label: "보츠와나(BW)", value: "BW" },
  { label: "콩고 민주공화국(CD)", value: "CD" },
  { label: "중앙아프리카공화국(CF)", value: "CF" },
  { label: "콩고(CG)", value: "CG" },
  { label: "코트디부아르(CI)", value: "CI" },
  { label: "카메룬(CM)", value: "CM" },
  { label: "카보 베르데(CV)", value: "CV" },
  { label: "지부티(DJ)", value: "DJ" },
  { label: "서사하라(EH)", value: "EH" },
  { label: "에리트레아(ER)", value: "ER" },
  { label: "에티오피아(ET)", value: "ET" },
  { label: "가봉(GA)", value: "GA" },
  { label: "가나(GH)", value: "GH" },
  { label: "감비아(GM)", value: "GM" },
  { label: "기니(GN)", value: "GN" },
  { label: "적도 기니(GQ)", value: "GQ" },
  { label: "기니비사우(GW)", value: "GW" },
  { label: "영령 인도양(IO)", value: "IO" },
  { label: "케냐(KE)", value: "KE" },
  { label: "코모로(KM)", value: "KM" },
  { label: "라이베리아(LR)", value: "LR" },
  { label: "레소토(LS)", value: "LS" },
  { label: "마다가스카르(MG)", value: "MG" },
  { label: "말리(ML)", value: "ML" },
  { label: "모리셔스(MU)", value: "MU" },
  { label: "말라위(MW)", value: "MW" },
  { label: "모잠비크(MZ)", value: "MZ" },
  { label: "나미비아(NA)", value: "NA" },
  { label: "니제르(NE)", value: "NE" },
  { label: "나이지리아(NG)", value: "NG" },
  { label: "불령 리유니온,코모도 제도(RE)", value: "RE" },
  { label: "르완다(RW)", value: "RW" },
  { label: "세이셸(SC)", value: "SC" },
  { label: "세인트 헬레나(SH)", value: "SH" },
  { label: "시에라리온(SL)", value: "SL" },
  { label: "세네갈(SN)", value: "SN" },
  { label: "소말리아(SO)", value: "SO" },
  { label: "사우스수단(SS)", value: "SS" },
  { label: "상투메 프린스페(ST)", value: "ST" },
  { label: "스와질란드(SZ)", value: "SZ" },
  { label: "차드(TD)", value: "TD" },
  { label: "불령 남부지역(TF)", value: "TF" },
  { label: "토고(TG)", value: "TG" },
  { label: "탄자니아(TZ)", value: "TZ" },
  { label: "우간다(UG)", value: "UG" },
  { label: "마요트(YT)", value: "YT" },
  { label: "남아프리카 공화국(ZA)", value: "ZA" },
  { label: "잠비아(ZM)", value: "ZM" },
  { label: "자이르(ZR)", value: "ZR" },
  { label: "짐바브웨(ZW)", value: "ZW" },
  { label: "남극 대륙(AQ)", value: "AQ" },
  { label: "아메리칸 사모아(AS)", value: "AS" },
  { label: "호주(AU)", value: "AU" },
  { label: "코코스 군도(CC)", value: "CC" },
  { label: "쿡 아일랜드(CK)", value: "CK" },
  { label: "캔톤아일랜드(CT)", value: "CT" },
  { label: "크리스마스 아일랜드(CX)", value: "CX" },
  { label: "피지(FJ)", value: "FJ" },
  { label: "미크로네시아(FM)", value: "FM" },
  { label: "괌(GU)", value: "GU" },
  { label: "허드 앤 맥도날드 군도(HM)", value: "HM" },
  { label: "존스턴섬(JT)", value: "JT" },
  { label: "키리바시(KI)", value: "KI" },
  { label: "마셜제도(MH)", value: "MH" },
  { label: "북마리아나 군도(MP)", value: "MP" },
  { label: "누벨칼레도니(NC)", value: "NC" },
  { label: "노폴크 아일랜드(NF)", value: "NF" },
  { label: "뉴헤브리디스(NH)", value: "NH" },
  { label: "드롬힝 마우드랜드(NQ)", value: "NQ" },
  { label: "나우루(NR)", value: "NR" },
  { label: "니우에(NU)", value: "NU" },
  { label: "뉴질랜드(NZ)", value: "NZ" },
  { label: "퍼시픽제도（미령）(PC)", value: "PC" },
  { label: "프랑스령 폴리네시아(PF)", value: "PF" },
  { label: "파푸아 뉴기니(PG)", value: "PG" },
  { label: "핏케언섬(PN)", value: "PN" },
  { label: "퍼시픽 아일랜드(PU)", value: "PU" },
  { label: "팔라우(PW)", value: "PW" },
  { label: "솔로몬 제도(SB)", value: "SB" },
  { label: "토켈라우(TK)", value: "TK" },
  { label: "통가(TO)", value: "TO" },
  { label: "투발루(TV)", value: "TV" },
  { label: "마이너 아우틀링 합중국 군도(UM)", value: "UM" },
  { label: "바누아투(VU)", value: "VU" },
  { label: "왈라스 & 퓨투나(WF)", value: "WF" },
  { label: "웨이크 아일랜드(WK)", value: "WK" },
  { label: "사모아(WS)", value: "WS" },
  { label: "코소보(KV)", value: "KV" },
];

/** 주 옴션 리스트 */
const STATE_OPTION_LIST: Record<
  "US" | "CA" | "IN",
  SearchSelectorOptionList<StateCode>
> = {
  US: [
    { label: "Alaska(AK)", value: "AK" },
    { label: "Alabama(AL)", value: "AL" },
    { label: "Arkansas(AR)", value: "AR" },
    { label: "Arizona(AZ)", value: "AZ" },
    { label: "California(CA)", value: "CA" },
    { label: "Colorado(CO)", value: "CO" },
    { label: "Connecticut(CT)", value: "CT" },
    { label: "District of Columbia(DC)", value: "DC" },
    { label: "Delaware(DE)", value: "DE" },
    { label: "Florida(FL)", value: "FL" },
    { label: "Georgia(GA)", value: "GA" },
    { label: "Hawaii(HI)", value: "HI" },
    { label: "Iowa(IA)", value: "IA" },
    { label: "Maine(ME)", value: "ME" },
    { label: "Michigan(MI)", value: "MI" },
    { label: "Minnesota(MN)", value: "MN" },
    { label: "Missouri(MO)", value: "MO" },
    { label: "Mississippi(MS)", value: "MS" },
    { label: "Montana(MT)", value: "MT" },
    { label: "North Carolina(NC)", value: "NC" },
    { label: "North Dakota(ND)", value: "ND" },
    { label: "Nebraska(NE)", value: "NE" },
    { label: "New Hampshire(NH)", value: "NH" },
    { label: "New Jersey(NJ)", value: "NJ" },
    { label: "New Mexico(NM)", value: "NM" },
    { label: "Nevada(NV)", value: "NV" },
    { label: "Idaho(ID)", value: "ID" },
    { label: "Illinois(IL)", value: "IL" },
    { label: "Indiana(IN)", value: "IN" },
    { label: "Kansas(KS)", value: "KS" },
    { label: "Kentucky(KY)", value: "KY" },
    { label: "Louisiana(LA)", value: "LA" },
    { label: "Massachusetts(MA)", value: "MA" },
    { label: "Maryland(MD)", value: "MD" },
    { label: "South Dakota(SD)", value: "SD" },
    { label: "Tennessee(TN)", value: "TN" },
    { label: "Texas(TX)", value: "TX" },
    { label: "Utah(UT)", value: "UT" },
    { label: "Virginia(VA)", value: "VA" },
    { label: "New York(NY)", value: "NY" },
    { label: "Ohio(OH)", value: "OH" },
    { label: "Oklahoma(OK)", value: "OK" },
    { label: "Oregon(OR)", value: "OR" },
    { label: "Pennsylvania(PA)", value: "PA" },
    { label: "PuertoRico(PR)", value: "PR" },
    { label: "RhodeIsland(RI)", value: "RI" },
    { label: "SouthCarolina(SC)", value: "SC" },
    { label: "Vermont(VT)", value: "VT" },
    { label: "Washington(WA)", value: "WA" },
    { label: "Wisconsin(WI)", value: "WI" },
    { label: "West Virginia(WV)", value: "WV" },
    { label: "Wyoming(WY)", value: "WY" },
  ],
  CA: [
    { label: "Alberta(AB)", value: "AB" },
    { label: "British Columbia(BC)", value: "BC" },
    { label: "Manitoba(MB)", value: "MB" },
    { label: "New Brunswick(NB)", value: "NB" },
    { label: "Newfoundland(NF)", value: "NF" },
    { label: "Nova Scotia(NS)", value: "NS" },
    { label: "Northwest Territories(NT)", value: "NT" },
    { label: "Nunavut(NU)", value: "NU" },
    { label: "Ontario(ON)", value: "ON" },
    { label: "Prince Edward Island(PE)", value: "PE" },
    { label: "Quebec(QC)", value: "QC" },
    { label: "Saskatchewan(SK)", value: "SK" },
    { label: "Yukon(YT)", value: "YT" },
  ],
  IN: [
    { label: "Andaman Nicobar(AN)", value: "AN" },
    { label: "Andhra Pradesh(AP)", value: "AP" },
    { label: "Bihar(BR)", value: "BR" },
    { label: "Chattisgarh(CG)", value: "CG" },
    { label: "Chandigarh(CH)", value: "CH" },
    { label: "Daman Diu(DD)", value: "DD" },
    { label: "Delhi(DL)", value: "DL" },
    { label: "Dadra And Nagar Haveli(DN)", value: "DN" },
    { label: "Goa(GA)", value: "GA" },
    { label: "Gujarat(GJ)", value: "GJ" },
    { label: "Himachal Pradesh(HP)", value: "HP" },
    { label: "Haryana(HR)", value: "HR" },
    { label: "Jharkhand(JH)", value: "JH" },
    { label: "Jammu Kashmir(JK)", value: "JK" },
    { label: "Karnataka(KA)", value: "KA" },
    { label: "Kerala(KL)", value: "KL" },
    { label: "Lakshadweep(LD)", value: "LD" },
    { label: "Maharashtra(MH)", value: "MH" },
    { label: "Assam(AS)", value: "AS" },
    { label: "Meghalaya(ML)", value: "ML" },
    { label: "Manipur(MN)", value: "MN" },
    { label: "Madhya Pradesh(MP)", value: "MP" },
    { label: "Mizoram(MZ)", value: "MZ" },
    { label: "Nagaland(NL)", value: "NL" },
    { label: "Orissa(OR)", value: "OR" },
    { label: "Punjab(PB)", value: "PB" },
    { label: "Puducherry(PY)", value: "PY" },
    { label: "Rajasthan(RJ)", value: "RJ" },
    { label: "Sikkim(SK)", value: "SK" },
    { label: "Tamil Nadu(TN)", value: "TN" },
    { label: "Tripura(TR)", value: "TR" },
    { label: "Uttaranchal(UA)", value: "UA" },
    { label: "Uttar Pradesh(UP)", value: "UP" },
    { label: "West Bengal(WB)", value: "WB" },
  ],
};

/** 택배사 종류 */
const PARCEL_COMPANY_SET = new Set([
  /** CJ대한통운 */
  "cj",
  /** 우체국 */
  "post",
  /** 한진택배 */
  "hanjin",
  /** 대신택배(화물) */
  "daesin",
  /** 건영택배(화물) */
  "kunyoung",
  /** 천일택배(화물) */
  "chunil",
  /** 경동(화물) */
  "kd",
  /** 로젠 */
  "logen",
  /** 롯데 */
  "lotte",
  /** UPS */
  "ups",
  /** 기타 */
  "etc",
]);

/** 화물차량 종류 */
const TRUCK_TYPE_SET = new Set([
  /** 오토바이 */
  "motorcycle",
  /** 다마스 */
  "damas",
  /** 라보 */
  "labo",
  /** 밴 */
  "van",
  /** 1톤 트럭 */
  "1ton",
  /** 1톤 윙바디 트럭 */
  "1ton_wingbody",
  /** 1.2톤&1.4톤 트럭 */
  "ton1_2_and_ton1_4",
  /** 2.5톤&3.5톤 트럭 */
  "ton2_5_and_ton3_5",
  /** 2.5톤&3.5톤 윙바디 트럭 */
  "ton2_5_and_ton3_5_wingbody",
  /** 5톤 트럭 */
  "5ton",
  /** 5톤 축차 */
  "5ton_rotor",
  /** 5톤 윙바디 트럭 */
  "5ton_wingbody",
  /** 7.5톤 트럭 */
  "ton7_5",
  /** 11톤 트럭 */
  "11ton",
  /** 14톤 트럭 */
  "14ton",
  /** 25톤 트럭 */
  "25ton",
]);

// TODO: 허용하는 것만 체크하는 식으로 기획&디자인 수정이 필요해보임
/** 해외 출고 등록 폼 UPS & FEDEX 비허용 특수문자 리스트 */
const UPS_AND_FEDEX_LIMITED_CHARACTER_LIST: string[] = [
  "%",
  "^",
  "<",
  ">",
  ";",
  "{",
  "}",
  "|",
];

/** 해외 출고 등록 폼 UPS & FEDEX 허용 특수문자 리스트 */
const UPS_AND_FEDEX_ALLOW_CHARACTER_LIST: string[] = [
  ",",
  "!",
  "@",
  ".",
  "#",
  "$",
  "&",
  "*",
  "(",
  ")",
  "-",
  "_",
  "+",
  "=",
  "?",
  "/",
  ":",
  '"',
  "[",
  "]",
  "₩",
];

const SHIPPING_STATUS_FOR_SHIPDA_LIST: Record<
  Exclude<ShippingStatus, "survey">,
  string
> = {
  waiting: "요청 확인 중",
  inProgress: "작업 중",
  cancel: "취소",
  close: "출고완료",
  delivering: "출고완료",
  done: "출고완료",
  return: "출고완료(반품)",
};

const OVERSEAS_PARCEL_COMPANY_OPTION_LIST: TableColumnFilterOptionList<FulfillmentParcelCompany> =
  [
    { label: "UPS", value: "ups" },
    { label: "FEDEX", value: "fedex" },
    { label: "기타", value: "etc" },
  ];

const COUNTRY_OPTION_LIST_WITHOUT_POSTAL_CODE = ["HK"];

export {
  SHIPPING_DELIVERY_TYPE,
  CREATED_FROM_TYPE,
  MALL_OPTION_TYPE,
  DOMESTIC_MALL_OPTION_LIST,
  OVERSEAS_MALL_OPTION_LIST,
  SHIPPING_STATUS_OPTION_LIST_FOR_ALL_TAB,
  SHIPPING_STATUS_OPTION_LIST_FOR_REQUEST_TAB,
  SHIPPING_STATUS_OPTION_LIST_FOR_COMPLETED_TAB,
  DOMESTIC_DELIVERY_TYPE_OPTION_LIST,
  OVERSEAS_DELIVERY_TYPE_OPTION_LIST,
  DELIVERY_NAME_OPTION_LIST,
  COUNTRY_CODE_TYPE,
  COUNTRY_OPTION_LIST,
  STATE_OPTION_LIST,
  PARCEL_COMPANY_SET,
  TRUCK_TYPE_SET,
  UPS_AND_FEDEX_LIMITED_CHARACTER_LIST,
  UPS_AND_FEDEX_ALLOW_CHARACTER_LIST,
  SHIPPING_STATUS_FOR_SHIPDA_LIST,
  OVERSEAS_PARCEL_COMPANY_OPTION_LIST,
  COUNTRY_OPTION_LIST_WITHOUT_POSTAL_CODE,
};
