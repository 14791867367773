import { Fragment, useState } from "react";

import Portal from "@sellernote/_shared/src/components/Portal";
import Barcode from "@sellernote/_shared/src/componentsToMoveToV1/Barcode";
import Button from "@sellernote/_shared/src/componentsToMoveToV1/button/Button";
import Icon from "@sellernote/_shared/src/componentsToMoveToV1/Icon";
import useModal, {
  ModalPropsV1,
} from "@sellernote/_shared/src/headlessComponents/useModal";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";

import TabLine from "../TabLine";
import ConfirmBeforeCloseModal from "./ConfirmBeforeCloseModal";
import Styled from "./index.styles";

/**
 * - contentWithCustomBody: 타이틀만 있고, 내용은 자유롭게
 * TODO: Modal은 _sds-v1으로 이전 중 이슈가 있어 headless인 'useModal'까지만 만들어둔 상태 (Task: AB#7044)
 */
function ModalComponent({
  active,
  title,
  onClose,
  allowsOverflow,
  barcodeValues, // Boful > 패킹화면의 Barcode 형식의 모달용
  needConfirmBeforeCloseModal,
  needConfirmBeforeCloseModalInfo,
  usePortal,
  className,
  "data-modal-type": dataModalType,
  ...propsByType
}: ModalPropsV1) {
  const [activeTabIndex, setActiveTabIndex] = useState(
    propsByType.uiType === "formInputWithTab"
      ? propsByType.defaultSelectedTabIndex || 0
      : 0
  );

  const {
    handleClickCloseModal,
    isVisibleConfirmBeforeCloseModal,
    setIsVisibleConfirmBeforeCloseModal,
  } = useModal({
    active,
    onClose,
    needConfirmBeforeCloseModal:
      propsByType.uiType === "formInputWithTab"
        ? propsByType.activeTabIndexOnCloseModal
          ? propsByType.activeTabIndexOnCloseModal.includes(activeTabIndex)
          : needConfirmBeforeCloseModal
        : needConfirmBeforeCloseModal,
  });

  if (!active) {
    return null;
  }

  const closeIconSize =
    propsByType.uiType === "formInput" ||
    propsByType.uiType === "formInputWithTab"
      ? 1.5
      : 2;

  return (
    <>
      <Styled.containerWrapper
        className={`${className ? className : ""} modal`}
        onClick={(e) => e.stopPropagation()}
        data-modal-type={dataModalType}
      >
        <Styled.container
          uiType={propsByType.uiType}
          onScroll={(e) => {
            e.stopPropagation();
          }}
          allowsOverflow={allowsOverflow}
          hasBorder={
            propsByType.uiType === "formInput" && propsByType.hasBorder
          }
          disableMaxHeight={
            (propsByType.uiType === "formInput" ||
              propsByType.uiType === "formInputWithTab") &&
            propsByType.disableMaxHeight
          }
          isWebNegativeTitle={
            propsByType.uiType === "titleOnly" && propsByType.isWebNegativeTitle
          }
          isWebNegativeActionPositive={
            propsByType.uiType === "titleOnly" &&
            propsByType.isWebNegativeActionPositive
          }
          // 버튼이 필요한 탭이 활성화된 상태인지 여부
          isActiveTabWithButton={
            propsByType.uiType === "formInputWithTab" &&
            propsByType.tabKeyListWithButton?.includes(activeTabIndex)
          }
          className="modal-contents"
        >
          <div className="header">
            <div className="title">{title}</div>

            {(propsByType.uiType === "formInput" ||
              propsByType.uiType === "formInputWithTab") && (
              <div className="desc">{propsByType.desc}</div>
            )}

            {onClose && (
              <Icon
                onClick={handleClickCloseModal}
                type="clear"
                size={closeIconSize}
                color={COLOR.grayScale_800}
              />
            )}
          </div>

          {(propsByType.uiType === "webNegative" ||
            propsByType.uiType === "content" ||
            propsByType.uiType === "contentWithCustomBody" ||
            propsByType.uiType === "formInput" ||
            propsByType.uiType === "PDAForm") &&
            propsByType.body && <div className="body">{propsByType.body}</div>}

          {propsByType.uiType === "formInputWithTab" && (
            <div className="form-input-with-tab-body-container">
              <div className="form-input-with-tab-body-header">
                <TabLine
                  tabList={propsByType.tabTitleList.map((title) => ({
                    title,
                  }))}
                  // defaultSelectedTabIndex={propsByType.defaultSelectedTabIndex}
                  selectedTabIndex={activeTabIndex}
                  setSelectedTabIndex={setActiveTabIndex}
                />
                {propsByType.buttonList && (
                  <div className="button-list">
                    {propsByType.buttonList.map((button, index) => (
                      <Fragment key={index}>{button}</Fragment>
                    ))}
                  </div>
                )}
              </div>

              {propsByType.uiType === "formInputWithTab" && (
                <div className="body">{propsByType.caseBy[activeTabIndex]}</div>
              )}
            </div>
          )}

          {propsByType.uiType !== "formInput" &&
            propsByType.uiType !== "formInputWithTab" &&
            propsByType.uiType !== "PDAForm" &&
            !barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="actions">
                {propsByType.actionNegative && (
                  <div
                    className="negative"
                    onClick={(e) => {
                      if (propsByType.actionNegative) {
                        propsByType.actionNegative.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionNegative.label}
                  </div>
                )}

                {propsByType.actionPositive && (
                  <div
                    className="positive"
                    onClick={(e) => {
                      if (propsByType.actionPositive) {
                        propsByType.actionPositive.handleClick();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {propsByType.actionPositive.label}
                  </div>
                )}
              </div>
            )}

          {propsByType.uiType === "formInput" && propsByType.submitButton && (
            <div className="submit-button-container">
              {propsByType.submitButton}
            </div>
          )}

          {propsByType.uiType !== "formInput" &&
            propsByType.uiType !== "formInputWithTab" &&
            propsByType.uiType !== "PDAForm" &&
            !!barcodeValues &&
            (propsByType.actionPositive || propsByType.actionNegative) && (
              <div className="barcode-actions">
                {barcodeValues.actionNegative && propsByType.actionNegative && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionNegative}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="dangerStroke"
                      size="block"
                      label={propsByType.actionNegative.label}
                      handleClick={(e) => {
                        if (propsByType.actionNegative) {
                          propsByType.actionNegative.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}

                {barcodeValues.actionPositive && propsByType.actionPositive && (
                  <div className="barcode-action-container">
                    <Barcode
                      value={barcodeValues.actionPositive}
                      options={{
                        displayValue: false,
                        margin: 0,
                      }}
                    />
                    <Button
                      theme="secondary"
                      size="block"
                      label={propsByType.actionPositive.label}
                      handleClick={(e) => {
                        if (propsByType.actionPositive) {
                          propsByType.actionPositive.handleClick();
                          e.stopPropagation();
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            )}
        </Styled.container>

        <Styled.dimmedBackground onClick={handleClickCloseModal} />
      </Styled.containerWrapper>

      {onClose && isVisibleConfirmBeforeCloseModal && (
        <ConfirmBeforeCloseModal
          setIsVisibleModal={setIsVisibleConfirmBeforeCloseModal}
          actionPositiveHandleClick={() => onClose()}
          needConfirmBeforeCloseModalInfo={needConfirmBeforeCloseModalInfo}
        />
      )}
    </>
  );
}

export default function Modal(props: Parameters<typeof ModalComponent>[0]) {
  return props.usePortal ? (
    <Portal selector="#app-portal">
      <ModalComponent {...props} />
    </Portal>
  ) : (
    <ModalComponent {...props} />
  );
}
